<template>
<div class="p-2">
  <b-card>
    <b-row>
      <b-col>
        <b-form-group label="Текущая система">
          <div class="d-flex">
            <system-selector v-model="system_id" class="flex-fill"></system-selector>
            <b-button class="mx-2" variant="primary" @click="refresh_profile"> Где я? </b-button>
          </div>
        </b-form-group>
        <b-form-group>
          <b-input-group prepend="Радиус поиска" class="mx-2">
            <b-form-input type="number" v-model="range"></b-form-input>
          </b-input-group>
          <b-input-group prepend="Население" class="mx-2" :append="num2text(Math.pow(population*10,3))">
            <b-form-input v-model="population" type="range" min="0" max="200" step="5"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Первичная экономика">
          <b-form-select v-model="primary_economy">
            <b-form-select-option :value="null">Не важно</b-form-select-option>
            <b-form-select-option value="Agriculture">Сельское хозяйство</b-form-select-option>
            <b-form-select-option value="HighTech">Высокие технологии</b-form-select-option>
            <b-form-select-option value="Industrial">Промышленная</b-form-select-option>
            <b-form-select-option value="Extraction">Добыча</b-form-select-option>
            <b-form-select-option value="Refinery">Переработка</b-form-select-option>
            <b-form-select-option value="Tourism">Туризм</b-form-select-option>
            <b-form-select-option value="Military">Военная</b-form-select-option>
            <b-form-select-option value="Service">Услуги</b-form-select-option>
            <b-form-select-option value="Colony">Колония</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Безопасность">
          <b-form-select v-model="security">
            <b-form-select-option :value="null">Не важно</b-form-select-option>
            <b-form-select-option value="anarchy">Анархия</b-form-select-option>
            <b-form-select-option value="low">Низк. ур. безопасности</b-form-select-option>
            <b-form-select-option value="medium">Средн. ур. безопасности</b-form-select-option>
            <b-form-select-option value="high">Высок. ур. безопасности</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Станция">
          <b-form-select v-model="station">
            <b-form-select-option :value="null">Не важно</b-form-select-option>
            <b-form-select-option value="bigspace">Большая космическая станция</b-form-select-option>
            <b-form-select-option value="space">Любая космическая станция</b-form-select-option>
            <b-form-select-option value="big">Любая с большой площадкой</b-form-select-option>
            <b-form-select-option value="any">Любая</b-form-select-option>
          </b-form-select>
        </b-form-group>
            <b-form-group label="Дистанция">
              <b-form-input v-model="distance2star" type="number" ></b-form-input>
            </b-form-group>
        <b-form-group label="Службы">
          <b-form-checkbox-group v-model="services">
                  <b-form-checkbox value="shipyard" size="sm">Верфь</b-form-checkbox>
                  <b-form-checkbox value="outfitting" size="sm">Снаряжение</b-form-checkbox>
                  <b-form-checkbox value="materialtrader" size="sm">Меняла</b-form-checkbox>
                  <b-form-checkbox value="techBroker" size="sm">Техноброкер</b-form-checkbox>
                  <b-form-checkbox value="facilitator" size="sm">Юрист</b-form-checkbox>
                  <b-form-checkbox value="blackmarket" size="sm">Черный рынок</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Состояние">
          <b-form-select v-model="faction_state" :options="faction_states">
            <template #first>
              <b-form-select-option :value="null">Не важно</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Принадлежность">
          <b-form-select v-model="faction_allegiance" >
            <b-form-select-option :value="null">Не важно</b-form-select-option>
            <b-form-select-option value="Alliance">Альянс</b-form-select-option>
            <b-form-select-option value="Empire">Империя</b-form-select-option>
            <b-form-select-option value="Federation">Федерация</b-form-select-option>
            <b-form-select-option value="Independent">Независимость</b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Статус Силы">
          <b-form-select v-model="power_state" :options="power_states">
            <template #first>
              <b-form-select-option :value="null">Не важно</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Сила">
          <b-form-select v-model="power" :options="powers"> 
            <template #first>
              <b-form-select-option :value="null">Не важно</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
        <b-form-group label="Особенности">
          <b-form-select v-model="scenarios" >
            <b-form-select-option :value="null">Не важно</b-form-select-option>
            <b-form-select-option value="HzRES">Место добычи ресурсов [Опасное]</b-form-select-option>
            <b-form-select-option value="HiRES">Место добычи ресурсов [Высок]</b-form-select-option>
            <b-form-select-option value="LoRES">Место добычи ресурсов [Низк]</b-form-select-option>
            <b-form-select-option value="RES">Место добычи ресурсов</b-form-select-option>
            <b-form-select-option value="CNB">Навигационный маяк под угрозой</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
        
    <b-button variant="outline-success" @click="doSearch">Найти</b-button>
  </b-card>
  
  
  <b-table :items="result" :fields="result_fields" bordered small striped responsive hover head-variant="dark" 
           @row-clicked="onClick">
    <template #cell(name)="data">
      <b-link :to="`/system/${data.item.system_id}`">{{data.item.name}}</b-link>
      <span>
        <b-button size="sm" variant="link" class="ml-2 p-0" 
                  @click="copyClipboard(data.item.name)">
          <b-icon icon="stickies"></b-icon>
        </b-button>
      </span>
    </template>
    <template #row-details="row">
      <b-card no-body>
        <b-table-simple striped small responsive caption-top v-if=row.item.stations>
          <caption>Станции</caption>
          <b-thead head-variant="dark">
            <b-tr>
              <b-th>Название</b-th>
              <b-th>Тип</b-th>
              <b-th>Экономика</b-th>
              <b-th>Площадка</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="st,idx in row.item.stations" :key="idx">
              <b-td>{{ st.name }}</b-td>
              <b-td>{{ st.type }}</b-td>
              <b-td>{{ st.economies }}</b-td>
              <b-td>{{ st.landing_pad }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </template>
  </b-table>
  
</div>
</template>

<script>
import Vue from 'vue';
import { WS } from '@/misc.js';
import SystemSelector from '@/components/SystemSelector.vue';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'SystemSearch',
    inject: ['getUserInfo'],
    components: {
        SystemSelector,
    },
    data() {
        return {
            system_id: null,
            range: 50,
            population: 0,
            security: null,
            primary_economy: null,
            faction_state: null,
            faction_states: [],
            faction_allegiance: null,
            power_state: null,
            power: null,
            scenarios: null,
            station: null,
            services: [],
            distance2star: null,
            result: [],
            result_fields: [
                { key: 'name', label: 'Система', sortable: true, thStyle: {"min-width":"300px"} },
                { key: 'distance', label: 'Расст', sortable: true, class: "text-right", thStyle: {width:"50px"} },
                { key: 'population', label: 'Население', sortable: true, class: "text-right",
                  formatter: value => {
                      if (value >= 1000000000) {
                          return (value / 1000000000).toFixed(2) + ' млрд.'
                      } else if (value >= 1000000) {
                          return (value / 1000000).toFixed(2) + ' млн.'
                      } else if (value >= 1000) {
                          return (value / 1000).toFixed(2) + ' тыс.'
                      } else {
                          return (value === null) ? '--' : value.toFixed(2);
                      }
                  }
                },
                { key: 'power', label: 'Сила', sortable: true, thStyle: {"min-width":"200px"} },
                { key: 'allegiance', label: 'Принадлежность', sortable: true },
                { key: 'government', label: 'Правительство', sortable: true, thStyle: {"min-width":"300px"} },
                { key: 'economy', label: 'Экономика', sortable: true, thStyle: {"min-width":"200px"} },
                { key: 'faction', label: 'Фракция', sortable: true, thStyle: {"min-width":"400px"} },
                { key: 'influence', label: 'Вл', sortable: true, class: "text-right",
                  thStyle: {width:"70px"},
                  formatter: value => {
                      return (value === null) ? '--' : value.toFixed(2);
                  }
                },
                { key: 'states', label: 'Состояния' },
                { key: 'st_amount', label: 'Станции' },
                
                { key: 'updated', label: 'Обновлено', sortable: true, thStyle: {width:"100px"},
                  formatter: value => {
                      return moment.unix(value).fromNow(true);
                  }
                },
            ],
            powers: [
              { 
                label: "Империя",
                options: ["Denton Patreus", "Zemina Torval","A. Lavigny-Duval", "Aisling Duval"]
              },
              {
                label: "Федералы",
                options: ["Felicia Winters", "Zachary Hudson"]
              },
              {
                label: "Альянс",
                options: ["Edmund Mahon"]
              },
              {
                label: "Независимые",
                options: ["Li Yong-Rui", "Yuri Grom", "Archon Delaine","Pranav Antal"]
              },
            ],
            power_states: [ 
              { value: "Controlled", text: "Контролируется" },
              { value: "HomeSystem", text: "Столица" },
              { value: "Prepared", text: "Подготовлена" },
              { value: "Turmoil", text: "Кризис" },
              { value: "Contested", text: "Оспаривается" },
              { value: "Exploited", text: "Эксплуатируется" },
              { value: "InPrepareRadius", text: "В радиусе подготовки" },
            ],
        }
    },
    async mounted() {
        try {
            this.faction_states = await WS.request('faction_states');
            let profile = await WS.request('user_profile');
            this.system_id = profile.system_id;
        } catch (err) {
            this.$bvModal.msgBoxOk(`user_profile: ${err}`);
        } 
    },
    methods: {
        async refresh_profile() {
            try {
                let profile = await WS.request('profile');
                this.system_id = profile.system_id;
            } catch (err) {
                this.$bvModal.msgBoxOk(`profile: ${err}`);
            }
        },
        async doSearch() {
            try {
                this.result = await WS.request('search_system',
                                               { system_id: this.system_id,
                                                 range: this.range,
                                                 population: Math.pow(this.population*10,3),
                                                 primary_economy: this.primary_economy,
                                                 security: this.security,
                                                 faction_state: this.faction_state,
                                                 faction_allegiance: this.faction_allegiance,
                                                 power: this.power,
                                                 power_state: this.power_state,
                                                 scenarios: this.scenarios,
                                                 station: this.station,
                                                 services: this.services,
                                                 distance2star: this.distance2star,
                                               });
                this.result.forEach((item) => {
                    Vue.set(item,'_showDetails', true);
                });
            } catch (err) {
                this.$bvModal.msgBoxOk(`${err}`);
            } 
        },
        onClick(item) {
            item._showDetails = !item._showDetails;
        },
        copyClipboard(value) {
          navigator.clipboard.writeText(value);
        },
        num2text(value) {
            // return new Intl.NumberFormat("ru", {notation:'scientific'}).format(value);
            if (value === null) return '--';
            if (value >= 1000000000) {
                return (value / 1000000000).toFixed(2) + ' млрд.'
            } else if (value >= 1000000) {
                return (value / 1000000).toFixed(2) + ' млн.'
            } else if (value >= 1000) {
                return (value / 1000).toFixed(2) + ' тыс.'
            } else {
                return value.toFixed(2);
            }
        },
    },
    filters: {
        timestamp(ts) {
            return moment.unix(ts).format("DD.MM.YYYY HH:mm");
        },
        bool2text(value) {
            return value ? 'Да' : 'Нет';
        }
    },
}
</script>

<style>
/*
  Fix an issue in vue-bootstrap v2.22.0:
  https://github.com/bootstrap-vue/bootstrap-vue/issues/6961 */
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
}
.maincontainer {
    height: 100vh;
}
.ddcontainer {
    overflow-y: scroll;
    max-height: calc(100vh - 150px);
}
</style>
