<template>
<div>
  <b-form inline class="my-2">
    <b-input-group prepend="Размер" class="mx-2" size="sm">
        <b-form-select v-model="ship_size" size="sm">
          <b-form-select-option :value="null">--  Все  --</b-form-select-option>
          <b-form-select-option value="S">Малые</b-form-select-option>
          <b-form-select-option value="M">Средние</b-form-select-option>
          <b-form-select-option value="L">Большие</b-form-select-option>
        </b-form-select>
    </b-input-group>
    <b-input-group prepend="Док" class="mx-2" size="sm">
      <b-form-select v-model="station" v-bind:options="stations" size="sm">
        <template #first>
          <b-form-select-option :value="null">--  Все  --</b-form-select-option>
          <b-form-select-option value="@">--  В ходу  --</b-form-select-option>
        </template>
      </b-form-select>
    </b-input-group>
    <b-input-group prepend="Сортировка" class="mx-2" size="sm">
        <b-form-select v-model="sort" size="sm" @input="refresh">
          <b-form-select-option value="type">По типу</b-form-select-option>
          <b-form-select-option value="used">По времени</b-form-select-option>
        </b-form-select>
    </b-input-group>
  </b-form>

  <b-card-group deck class="flex-wrap">
    <b-card v-for="ship in fleet_filtered" :key="ship.id"
            class="m-2" style="min-width: 500px;max-width: 700px;">
      <template #header>
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">{{ ship.type }}  ({{ship.size}})</h5>
          <span>{{ship.last_time | timestamp}}</span>
        </div>
      </template>
      <b-card-text class="m-0">
        <!-- <b-media> -->
          <!-- <template #aside> -->
          <!--     <img :src="`/icons/ship-${ship.type}.png`" width="150"> -->
          <!-- </template> -->
          <!-- <p> Имя: {{ ship.name }}</p> -->
          <!-- <p> ID: {{ ship.identifier }}</p> -->
        <!-- </b-media> -->
        <b-table-simple small>
          <b-tbody>
            <b-tr>
              <b-td class="text-right align-middle">Имя:</b-td>
              <b-td colspan="3">
                <b-input-group size="sm">
                  <b-form-input v-model="ship.name" :readonly="ship.name_locked"></b-form-input>
                  <b-input-group-append>
                    <b-form-checkbox size="sm" v-model="ship.name_locked" button button-variant="info"
                                     @change="onNameEdit(ship,$event)">
                      <b-icon icon="unlock" v-show="ship.name_locked"></b-icon>
                      <b-icon icon="save" v-show="!ship.name_locked"></b-icon>
                    </b-form-checkbox>
                  </b-input-group-append>
                </b-input-group>
              </b-td>
              <b-td colspan="2" class="align-middle">{{ ship.identifier }}</b-td>
            </b-tr>
            <b-tr>
              <b-td class="text-right align-middle">Применение:</b-td>
              <b-td colspan="5">
                <b-input-group size="sm">
                  <b-form-input v-model="ship.note" :readonly="ship.note_locked"></b-form-input>
                  <b-input-group-append>
                    <b-form-checkbox size="sm" v-model="ship.note_locked" button button-variant="info"
                                     @change="onNoteEdit(ship,$event)">
                      <b-icon icon="unlock" v-show="ship.note_locked"></b-icon>
                      <b-icon icon="save" v-show="!ship.note_locked"></b-icon>
                    </b-form-checkbox>
                  </b-input-group-append>
                </b-input-group>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="text-right">Система:</b-td>
              <b-td colspan="5"><b-link :to="`/system/${ship.system_id}`">{{ ship.system }}</b-link></b-td>
            </b-tr>
            <b-tr>
              <b-td class="text-right">Док:</b-td>
              <b-td colspan="5"><b-link :to="`/station/${ship.station_id}`">{{ ship.station }}</b-link></b-td>
            </b-tr>
            <b-tr>
              <b-td class="text-right">Цена:</b-td>
              <b-td>{{ ship.value | num2text }}</b-td>
              <b-td class="text-right">Прыжок:</b-td>
              <b-td>{{ ship.max_jump }}</b-td>
              <b-td class="text-right">Трюм:</b-td>
              <b-td>{{ ship.cargo_capacity }}</b-td>
            </b-tr>
            <b-tr v-if="ship.fit.speed">
              <b-td class="text-right">Скорость:</b-td>
              <b-td colspan="2">{{ship.fit.speed}}/{{ship.fit.speedLaden}}</b-td>
              <b-td class="text-right">Буст:</b-td>
              <b-td colspan="2">{{ship.fit.boost}}/{{ship.fit.boostLaden}}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-table-simple small>
          <b-tbody>
            <b-tr>
              <b-th></b-th>
              <b-th></b-th>
              <b-th>Кинетика</b-th>
              <b-th>Термо</b-th>
              <b-th>Взрыв</b-th>
            </b-tr>
            <b-tr v-if="ship.fit.armourValue">
              <b-td class="text-right">Броня:</b-td>
              <b-td>{{ ship.fit.armourValue }}</b-td>
              <b-td>{{ ship.fit.armourKineticResist }} % </b-td>
              <b-td>{{ ship.fit.armourThermicResist }} % </b-td>
              <b-td>{{ ship.fit.armourExplosiveResist }} % </b-td>
            </b-tr>
            <b-tr v-if="ship.fit.shieldValue">
              <b-td class="text-right">Щит:</b-td>
              <b-td>{{ ship.fit.shieldValue }} мДж</b-td>
              <b-td>{{ ship.fit.shieldKineticResist }} %</b-td>
              <b-td>{{ ship.fit.shieldThermicResist }} %</b-td>
              <b-td>{{ ship.fit.shieldExplosiveResist }} %</b-td>
            </b-tr>
          </b-tbody>          
        </b-table-simple>
      </b-card-text>
      <b-button variant="info" @click="showLoadout(ship.ship_id)">Фит</b-button>
    </b-card>
  </b-card-group>

  <b-modal id="fleet2_loadout-modal" size="xl" hide-header ok-only>
    <ship-loadout :user_id="user_id" :ship_id="ship_id"></ship-loadout>
  </b-modal>

</div>
</template>

<script>
import Vue from 'vue';
import { WS } from '@/misc.js';
import ShipLoadout from "@/components/ShipLoadout.vue";
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'CmdrFleet2',
    inject: ['getUserInfo'],
    components: {
        ShipLoadout,
    },
    data() {
        return {
            user_id: null,
            fleet: [],
            ship_size: null,
            sort: null,
            ship_id: null,
            stations: [],
            station: null,
        }
    },
    computed: {
        fleet_filtered() {
            return this.fleet.filter(item => {
                return ((this.ship_size === null || item.size == this.ship_size) &&
                        (this.station === null || item.station == this.station ||
                           (this.station == '@' && item.station === null)));
            });
        }
    },
    watch: {
        async $route(to) {
            console.log('CmdrFleet2 routed',to)
            if (to.name == 'fleet2') {
                if (to.params.user_id) {
                    this.user_id = to.params.user_id;
                }
                await this.refresh();
            }
        }
    },
    async mounted() {
        if (this.$route.params.user_id) {
            this.user_id = this.$route.params.user_id;
        }
        await this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.fleet = await WS.request('fleet', this.user_id);
                this.stations = [];
                this.fleet.forEach((item) => {
                    if (item.station && !this.stations.includes(item.station)) {
                        this.stations.push(item.station);
                    }
                    Vue.set(item,'name_locked', true);
                    Vue.set(item,'note_locked', true);
                });
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
        showLoadout(ship_id) {
            this.ship_id = ship_id;
            this.$bvModal.show("fleet2_loadout-modal");
        },
        async onNameEdit(ship,event) {
            if (event) {
                try {
                    let result = await WS.request('fleet_update_name',
                                                  {
                                                      user_id: this.user_id,
                                                      ship_id: ship.ship_id,
                                                      value: ship.name
                                                  });
                    ship.name = result;
                } catch (err) {
                    this.$bvModal.msgBoxOk(`update: ${err}`);
                }
            }
        },
        async onNoteEdit(ship,event) {
            if (event) {
                try {
                    let result = await WS.request('fleet_update_note',
                                                  {
                                                      user_id: this.user_id,
                                                      ship_id: ship.ship_id,
                                                      value: ship.note
                                                  });
                    ship.note = result;
                } catch (err) {
                    this.$bvModal.msgBoxOk(`update: ${err}`);
                }
            }
        },
    },
    filters: {
        num2text(value) {
            // return new Intl.NumberFormat("ru", {notation:'scientific'}).format(value);
            if (value >= 1000000000) {
                return (value / 1000000000).toFixed(2) + ' млрд.'
            } else if (value >= 1000000) {
                return (value / 1000000).toFixed(2) + ' млн.'
            } else if (value >= 1000) {
                return (value / 1000).toFixed(2) + ' тыс.'
            } else {
                return (value === null) ? '--' : value.toFixed(2);
            }
        },
        timestamp(ts) {
            return moment.unix(ts).format("DD.MM.YYYY HH:mm");
        },
    }
}
</script>

<style>

</style>
