<template>
<div id="app">
  <b-modal v-model="showlogin" title="Авторизация" ok-title="Войти"
           no-close-on-backdrop no-close-on-esc ok-only hide-header-close hide-backdrop
           @ok="login" > 
    <b-form-group label="Имя пользователя">
      <b-input type="text" v-model="username" v-on:keyup.enter="login" :autofocus="true" />
    </b-form-group>
    <b-form-group label="Пароль">
      <b-input type="password" v-model="password" v-on:keyup.enter="login"/>
    </b-form-group>
    
    <template #modal-footer="{ ok }">
      <b-button variant="warning" v-b-modal.newuser-modal> Новый </b-button>
      <b-button variant="primary" @click="ok()"> Войти </b-button>
    </template>
  </b-modal>
  
  <b-modal id="newuser-modal" size="lg" hide-header @ok="register">
    <b-form-group label="Имя" label-cols="6">
      <b-input type="text" v-model="newuser.name" />
    </b-form-group>
    <b-form-group label="Логин" label-cols="6">
      <b-input type="text" v-model="newuser.login" />
    </b-form-group>
    <b-form-group label="Пароль" label-cols="6">
      <b-input type="password" v-model="newuser.password" />
    </b-form-group>
    <b-form-group label="Повтор пароля" label-cols="6">
      <b-input type="password" v-model="password_check" />
    </b-form-group>
    <b-alert variant="danger" :show="newuser.password !== password_check">Пароли не совпадают!</b-alert>    
    <b-form-group label="Фракция">
      <faction-selector v-model="newuser.faction_id" nofavor></faction-selector>
    </b-form-group>
  </b-modal>
  
  <div v-if="authorized">
    <b-navbar toggleable="lg" type="dark" variant="info" class="my-0 py-0">
      <b-navbar-brand v-if="userInfo.squadron_id == 1" href="https://docs.google.com/spreadsheets/d/10WkRLV3k4FpQtB6c92OmRT842mlfCtelaVQbELpPr1E">BGS</b-navbar-brand>
      <b-navbar-brand v-else>BGS</b-navbar-brand>      
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/cmdr">Командир</b-nav-item>
          <b-nav-item to="/system">Система</b-nav-item>
          <b-nav-item to="/faction">Фракция</b-nav-item>
          <b-nav-item-dropdown text="Поиск" right>
            <b-dropdown-item to="/search">Экспансия</b-dropdown-item>
            <b-dropdown-item to="/commodity">Товары</b-dropdown-item>
            <b-dropdown-item to="/outfitting">Док</b-dropdown-item>
            <b-dropdown-item to="/search_station">Станция</b-dropdown-item>
            <b-dropdown-item to="/search_body">Тело</b-dropdown-item>
            <b-dropdown-item to="/search_system">Система</b-dropdown-item>
            <b-dropdown-item to="/trade-s2s">Торговля Один к одному </b-dropdown-item>
            <b-dropdown-item to="/trade-s2m">Торговля Один ко многим </b-dropdown-item>
            <b-dropdown-item to="/search_parking">Парковка</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/ticks">Тики</b-nav-item>
          <b-nav-item to="/engineers">Инженеры</b-nav-item>
          <b-nav-item to="/techbroker">Техноброкеры</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template #button-content>
              <em>{{ userInfo.name }}</em>
            </template>
            <b-dropdown-item to="/settings">Настройки</b-dropdown-item>
            <b-dropdown-item href="/api/link">Авторизовать профиль</b-dropdown-item>
            <b-dropdown-item @click="logout">Выход</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>        
      </b-collapse>
    </b-navbar>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import VueRouter from 'vue-router';
import { WS } from '@/misc.js';

import CommanderSquad from '@/components/CommanderSquad.vue';
import CmdrFleet from '@/components/CmdrFleet.vue';
import CmdrFleet2 from '@/components/CmdrFleet2.vue';
import CmdrModules from '@/components/CmdrModules.vue';
import CmdrMaterials from '@/components/CmdrMaterials.vue';
import CmdrStatistics from '@/components/CmdrStatistics.vue';
import FleetCarrier from '@/components/FleetCarrier.vue';

import FactionView from '@/components/FactionView.vue';
import SystemView from '@/components/SystemView.vue';
import StationView from '@/components/StationView.vue';

import FactionSelector from '@/components/FactionSelector.vue';
import SearchExpansion from '@/components/SearchExpansion.vue';
import CommoditySearch from '@/components/CommoditySearch.vue';
import ModuleSearch from '@/components/ModuleSearch.vue';
import StationSearch from '@/components/StationSearch.vue';
import BodySearch from '@/components/BodySearch.vue';
import SystemSearch from '@/components/SystemSearch.vue';
import ParkingSearch from '@/components/ParkingSearch.vue';
import TradeS2S from '@/components/TradeS2S.vue';
import TradeS2M from '@/components/TradeS2M.vue';
import TickTime from '@/components/TickTime.vue';
import EDEngineers from '@/components/EDEngineers.vue';
import ModuleBlueprint from '@/components/ModuleBlueprint.vue';
import TechBroker from '@/components/TechBroker.vue';
import CmdrSettings from '@/components/CmdrSettings.vue'

Vue.use(VueRouter);

const routes = [
    { path: '/cmdr', component: CommanderSquad },
    { path: '/statistics/:user_id', name: 'statistics', component: CmdrStatistics },
    { path: '/materials/:user_id', name: 'materials', component: CmdrMaterials },
    { path: '/fleet/:user_id', name: 'fleet', component: CmdrFleet },
    { path: '/fleet2/:user_id', name: 'fleet2', component: CmdrFleet2 },
    { path: '/modules/:user_id', name: 'modules', component: CmdrModules },
    { path: '/fc/:callsign', name: 'fleetcarrier', component: FleetCarrier },
    { path: '/system', name: 'system', component: SystemView },
    { path: '/system/:system_id', name: 'system', component: SystemView },
    { path: '/faction', name: 'faction', component: FactionView },
    { path: '/faction/:faction_id', name: 'faction', component: FactionView },
    { path: '/station/:station_id', name: 'station', component: StationView },
    { path: '/trade-s2s', component: TradeS2S },
    { path: '/trade-s2m', component: TradeS2M },
    { path: '/search', component: SearchExpansion },
    { path: '/commodity', component: CommoditySearch },
    { path: '/search_station', component: StationSearch },
    { path: '/search_body', component: BodySearch },
    { path: '/search_system', component: SystemSearch },
    { path: '/search_parking', component: ParkingSearch },
    { path: '/outfitting', component: ModuleSearch },
    { path: '/ticks', component: TickTime },
    { path: '/engineers', name: 'engineers', component: EDEngineers },
    { path: '/blueprint/:module/:engineer/:user', name: 'moduleblueprint', component: ModuleBlueprint },
    { path: '/techbroker', name: 'techbroker', component: TechBroker },
    { path: '/settings', name: 'settings', component: CmdrSettings },

]
const router = new VueRouter({
    routes, 
})

export default {
    name: 'App',
    metaInfo: {
        title: 'ED: BGS Monitoring',
        htmlAttrs: { lang: 'ru' },
        meta: [
            { charset: 'utf-8' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no' }
        ]
    },
    components: {
        FactionSelector,
    },
    router: router,
    data() {
        return {
            userInfo: {},
            showlogin: false,
            authorized: false,
            username: "",
            password: "",
            newuser: { name: "", login: "", password: "", faction_id: null },
            password_check: "",
        }
    },
    async mounted() {
        try {
            this.userInfo = await WS.request('hello');
            this.authorized = true;
            this.showlogin = false;
        } catch (err) {
            this.showlogin = true;
            this.authorized = false;
        } 
    },
    provide: function () {
        return {
            getUserInfo: this.getUserInfo,
        }
    },
    methods: {
        getUserInfo() {
            return this.userInfo;
        },
        async login(bvModalEvt) {
            bvModalEvt.preventDefault();
            try {
                this.userInfo = await WS.login(this.username, this.password)
                this.$nextTick(() => {
                    this.authorized = true;
                    this.showlogin = false;
                });
            } catch (err) {
                this.$bvModal.msgBoxOk(`login: ${err}`);
            }
        },
        async logout() {
            try {
                await WS.logout();
                this.$nextTick(() => {
                    this.authorized = false;
                    this.showlogin = true;
                });
            } catch (err) {
                this.$bvModal.msgBoxOk(`logout: ${err}`);
            }
        },
        async register() {
            try {
                this.userInfo = await WS.request('register', this.newuser)
                this.$nextTick(() => {
                    this.authorized = true;
                    this.showlogin = false;
                });
            } catch (err) {
                alert(err);
            }
        },
    },
    
}
</script>

<style>

</style>
