<template>
<div class="flex-column">
  <b-table-simple hover striped small caption-top responsive>
    <b-tbody>
      <b-tr>
        <b-th>Стоимость корпуса:</b-th>
        <b-td>{{ship_data.hull_value | num2text}}</b-td>
        <b-th>Масса без груза:</b-th>
        <b-td>{{ship_data.unladen_mass}}</b-td>
      </b-tr>
      <b-tr>
        <b-th>Стоимость модулей:</b-th>
        <b-td>{{ship_data.modules_value | num2text}}</b-td>
        <b-th>Грузовместимость:</b-th>
        <b-td>{{ship_data.cargo_capacity}}</b-td>
      </b-tr>
      <b-tr>
        <b-th>Страховка:</b-th>
        <b-td>{{ship_data.rebuy | num2text}}</b-td>
        <b-th>Макс.прыжок:</b-th>
        <b-td>{{ship_data.max_jump_range}}</b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
  
  <div v-for="group,idx in ship_data.loadout" :key="idx">
    <b-table-simple hover striped small caption-top responsive>
      <caption><h5>{{group.groupname}}</h5></caption>
      <b-thead head-variant="dark">
        <b-tr>
          <b-th>Слот</b-th>
          <b-th>Модуль</b-th>
          <b-th>Класс</b-th>
          <b-th>Чертеж</b-th>
          <b-th>Эксп.эффект</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="item,idx in group.modules" :key="idx">
          <b-th>{{ item.slot }}</b-th>
          <b-td> {{ item.module_name }} </b-td>
          <b-td> {{ item.class }}{{ item.rating }}<span v-if="item.mount">-{{ item.mount }}</span> </b-td>
          <b-td>
            {{ item.blueprint_name }}
            <span v-if="item.blueprint_level"> ({{item.blueprint_level}}ур.)</span>
          </b-td>
          <b-td>{{item.exp_name}}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>

  <b-button-group>
    <b-button @click="export_loadout('edsy')">EDSY</b-button>
    <b-button @click="export_loadout('coriolis')">Coriolis</b-button>
  </b-button-group>
</div>
</template>

<script>
import { WS } from '@/misc.js';
import moment from 'moment';

moment.locale('ru');

export default {
    name: 'ShipLoadout',
    inject: ['getUserInfo'],
    props: {
        user_id: Number,
        ship_id: Number,
    },
    components: {
    },
    data() {
        return {
            ship_data: {},
        }
    },
    async mounted() {
        await this.refresh();
    },
    methods: {
        async refresh() {
            try {
                this.ship_data = await WS.request('loadout', { user_id: this.user_id, ship_id: this.ship_id } );
            } catch (err) {
                this.$bvModal.msgBoxOk(`refresh: ${err}`);
            }
        },
        async export_loadout(site) {
            try {
                let url = await WS.request(site+'_url', { user_id: this.user_id, ship_id: this.ship_id });
                window.open(url, '_blank');
            } catch (err) {
                this.$bvModal.msgBoxOk(`export: ${err}`);
            }
        },
    },
    filters: {
        num2text(value) {
            // return new Intl.NumberFormat("ru", {notation:'scientific'}).format(value);
            if (value >= 1000000000) {
                return (value / 1000000000).toFixed(2) + ' млрд.'
            } else if (value >= 1000000) {
                return (value / 1000000).toFixed(2) + ' млн.'
            } else if (value >= 1000) {
                return (value / 1000).toFixed(2) + ' тыс.'
            } else {
                return (value == null) ? '--' : value.toFixed(2);
            }
        },
        timestamp(ts) {
            return moment.unix(ts).format("DD.MM.YYYY HH:mm");
        },
    },
}
</script>

<style>

</style>
